<template>
    <div class="page-content">
      <ScheduleForm />
      <div class="schedule main-content">
        <div class="page-header">
          <h1>{{ $t('WorkShift') }}</h1>
  
          <div class="view-toolbars">
            <div class="tool">
              <button class="btn btn-primary" @click="displayModal">
                <i class="el-icon-plus"></i>
                Add Employee Schedule
              </button>
            </div>
          </div>
        </div>
  
        <div class="timetable">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Employee</th>
                <th scope="col" v-for="workshift in workshifts">
                  {{ workshift.day }}
                  {{ workshift.weekday }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="employee in employees">
                <td scope="row">{{ employee.employee_name }}</td>
                <td v-for="workshift in workshifts">
                  <div class="workshift-content" v-for="workshiftContent in workshift.workshifts">
                    <div v-if="employee.id === workshiftContent.employee_id">
                      {{ formatDateTime(workshiftContent.start_time, 'H:mm') }} - {{ formatDateTime(workshiftContent.end_time, 'H:mm') }}
                      <a href="#" class="workshift-edit-link" v-if="!workshiftContent.attendanced" @click="displayEditModal(workshiftContent)">Edit</a>
                      <a href="#" class="workshift-delete-link" v-if="!workshiftContent.attendanced" @click="deleteWorkshift(workshiftContent)">Delete</a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import dayjs from 'dayjs';
  import ScheduleForm from '@/components/hr/ScheduleForm';
  import Common from '@/lib/common';
  import Hr from '@/lib/hr';
  
  export default {
    name: 'Schedule',
    components: {
      ScheduleForm,
    },
    data(){
      return{
        workshifts: [],
        employees: [],
      };
    },
    mounted(){
      this.loadEmployees();
      this.loadWorkshift();
    },
    methods:{
      displayModal(){
        this.displayScheduleForm();
      },
      displayEditModal(workshift){
        this.setCurrentWorkshift(workshift);
        this.displayScheduleForm();
      },
      async loadEmployees(){
        const loginInfo = Common.getLoginInfo();
        try{
          const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
          this.employees = employees;
        }catch(err){
          console.log(err);
        }
      },
      async deleteWorkshift(workshift){
        const loginInfo = Common.getLoginInfo();
        try{
          await Hr.deleteWorkshift(this.apiUrl, workshift.id, loginInfo);
          location.reload();
        }catch(err){
          console.log(err);
        }
      },
      formatDateTime(timestamp, format){
        const date = Common.formatDateTime(timestamp, format);
        return date;
      },
      async loadWorkshift(){
        const loginInfo = Common.getLoginInfo();
        const workshifts = await Hr.loadAllWorkshift(this.apiUrl, loginInfo);
        this.workshifts = workshifts;
        this.setCurrentWorkshifts(this.workshifts);
      },
      ...mapActions(['displayScheduleForm', 'hiddenScheduleForm', 'setCurrentWorkshift', 'setCurrentWorkshifts']),
    },
    computed: mapState({
      currentUser: state => state.currentUser,
      apiUrl: state => state.apiUrl,
    }),
  }
  
  </script>
  
  <style lang="scss">
  .workshift-edit-link{
    margin-left: 10px;
  }
  
  .workshift-delete-link{
    margin-left: 10px;
    color: red;
  }
  
  .schedule-model{
    .dialog-footer{
      margin-top: 50px;
    }
  }
  
  .timetable{
    margin-top: 50px;
  }
  </style>